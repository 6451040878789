import { ISettingsParam, ISettingsParams } from '@wix/tpa-settings';
import { appName } from '../../../.application.json';
import { Section, SectionTypes } from './types';

export type IComponentSettings = ISettingsParams<{
  greetingsText: ISettingsParam<string>;
  contentWidth: ISettingsParam<number>;
  sections: ISettingsParam<string>;
  headerVisibility: ISettingsParam<boolean>;
  sidebarVisibility: ISettingsParam<boolean>;
}>;

export const defaultSections: Section[] = [
  {
    type: SectionTypes.TITLE_TAGLINE,
    visible: true,
  },
  {
    type: SectionTypes.DESCRIPTION,
    visible: true,
  },
  {
    type: SectionTypes.DETAILS,
    visible: true,
  },
  {
    type: SectionTypes.POLICY,
    visible: true,
  },
  {
    type: SectionTypes.CONTACT,
    visible: true,
  },
  {
    type: SectionTypes.SCHEDULING,
    visible: true,
  },
];

export const componentSettings: IComponentSettings = {
  greetingsText: {
    key: 'greetingsText',
    getDefaultValue: () => `to ${appName}`,
  },
  contentWidth: {
    key: 'contentWidth',
    getDefaultValue: () => 50,
  },
  sections: {
    key: 'sections',
    getDefaultValue: () => JSON.stringify(defaultSections),
  },
  headerVisibility: {
    key: 'headerVisibility',
    getDefaultValue: () => true,
  },
  sidebarVisibility: {
    key: 'sidebarVisibility',
    getDefaultValue: () => true,
  },
};
