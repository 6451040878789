import {
  CreateControllerFn,
  ControllerParams,
} from 'yoshi-flow-editor-runtime';
import { getSettingsValues } from '@wix/tpa-settings';
import { componentSettings } from './componentSettings';
import HttpClient from 'yoshi-serverless-client';
import { greet } from '../../api/greet.api';

const createController: CreateControllerFn = async ({
  flowAPI,
  appData,
}: ControllerParams) => {
  const errorReporter = appData?.ravenReporter || (() => null);
  const { setProps } = flowAPI.controllerConfig;

  const publicData = flowAPI.controllerConfig.config.publicData.COMPONENT || {};
  const settings = getSettingsValues(publicData, componentSettings);

  return {
    async pageReady() {
      const httpClient = new HttpClient();
      const result = await httpClient.request(greet)('Yaniv');
      setProps({
        greetingsText: result, // settings.greetingsText,
        contentWidth: settings.contentWidth,
        sections: JSON.parse(settings.sections),
        displayOptions: {
          header: settings.headerVisibility,
          sidebar: settings.sidebarVisibility,
        },
      });
    },
    updateConfig($w, config) {
      const updatedPublicData = config.publicData.COMPONENT || {};
      const updatedSettings = getSettingsValues(
        updatedPublicData,
        componentSettings,
      );

      setProps({
        greetingsText: updatedSettings.greetingsText,
        contentWidth: updatedSettings.contentWidth,
        sections: JSON.parse(updatedSettings.sections),
        displayOptions: {
          header: updatedSettings.headerVisibility,
          sidebar: updatedSettings.sidebarVisibility,
        },
      });
    },
  };
};

export default createController;
